"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleMapController = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
class GoogleMapController {
    constructor() {
        this.center = { lat: 37.507306666666665, lng: 127.13705 };
        this.markers = {};
        this.initMap = (mapContainer) => __awaiter(this, void 0, void 0, function* () {
            // Request needed libraries.
            const { Map } = yield google.maps.importLibrary('maps');
            // The map, centered at Uluru
            this.map = new Map(mapContainer, {
                zoom: 12,
                center: this.center,
                mapId: 'DEMO_MAP_ID',
            });
        });
        this.createInfoWindowsByEntity = (entity) => __awaiter(this, void 0, void 0, function* () {
            const { AdvancedMarkerElement } = yield google.maps.importLibrary('marker');
            const now = new Date();
            const oldestAgeInMinute = 180;
            for (const email in entity) {
                for (const utcTime in entity[email]) {
                    const sample = entity[email][utcTime];
                    const lastPositionDate = new Date(utcTime);
                    const ageInMinute = (now.getTime() - lastPositionDate.getTime()) / (1000 * 60);
                    if (this.markers[email] === undefined) {
                        this.markers[email] = new AdvancedMarkerElement({ map: this.map, title: email });
                    }
                    this.markers[email].position = { lat: sample.latitude, lng: sample.longitude };
                    this.markers[email].content = (0, lib_es2015_1.buildNodeByOuterHTML)(`<div class="info-window-container" style="background-color: white; border: 1px solid black;">
              email: ${email}<br/>
              time: ${lastPositionDate.toLocaleString()}
              <div style="height: 5px; background-color: rgb(${Math.floor((144 / oldestAgeInMinute) * Math.min(oldestAgeInMinute, ageInMinute) + 6)}, ${Math.floor((-26 / oldestAgeInMinute) * Math.min(oldestAgeInMinute, ageInMinute) + 176)}, ${Math.floor((144 / oldestAgeInMinute) * Math.min(oldestAgeInMinute, ageInMinute) + 6)});"></div>
            </div>`);
                }
            }
        });
    }
    emptyInfoWindows() {
        for (const email in this.markers) {
            this.markers[email].map = null;
            delete this.markers[email];
        }
    }
    setCenterByInfoWindowEmail(email) {
        const targetMarker = this.markers[email];
        if (targetMarker === undefined || targetMarker.position === null || targetMarker.position === undefined) {
            alert('no information for the user: ' + email);
            return;
        }
        this.map.setCenter(targetMarker.position);
    }
}
exports.GoogleMapController = GoogleMapController;
