"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineViews = exports.routes = void 0;
const home_page_1 = require("../main/home-page");
const api_token_generator_1 = require("../api-token-generator/api-token-generator");
const location_history_1 = require("../location/location-history");
const recent_location_1 = require("../location/recent-location");
exports.routes = [
    {
        path: '/web',
        tag: 'home-page',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: '/web/location/history',
        tag: 'location-history',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: '/web/location/recent',
        tag: 'recent-location',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: '/web/member/api-token',
        tag: 'api-token-generator',
        pathParamNames: [],
        queryParamNames: [],
    },
];
const defineViews = () => {
    (0, location_history_1.defineLocationHistory)('location-history');
    (0, recent_location_1.defineRecentLocation)('recent-location');
    (0, api_token_generator_1.defineApiTokenGenerator)('api-token-generator');
    (0, home_page_1.defineHomePage)('home-page');
};
exports.defineViews = defineViews;
