"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const Constants = require("./constants");
const lib_es2015_1 = require("@hodol/webapp-common/lib-es2015");
class Apis {
    getLastLocationByEmails(emails, startTimeLocal) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/last?emails=${emails.join(',')}&start_time_utc=${(0, lib_es2015_1.toUTCStringWithoutMilliseconds)(new Date(startTimeLocal))}`, {
                method: 'GET',
            }, (lib_es2015_1.defaultCodeProcessor));
        });
    }
    getEmails() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/emails`, {
                method: 'GET',
            }, (lib_es2015_1.defaultCodeProcessor));
        });
    }
    getLocationHistory(userName, startDatetime, stopDatetime) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}?emails=${userName}&start_time_utc=${(0, lib_es2015_1.toUTCStringWithoutMilliseconds)(startDatetime)}&stop_time_utc=${(0, lib_es2015_1.toUTCStringWithoutMilliseconds)(stopDatetime)}`, {
                method: 'GET',
            }, (lib_es2015_1.defaultCodeProcessor));
        });
    }
}
exports.default = Apis;
