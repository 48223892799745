"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineApiTokenGenerator = exports.ApiTokenGenerator = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const apis_1 = require("./apis");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
class ApiTokenGenerator extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.style.setProperty('display', 'block');
        this.innerHTML = `
    <button>New Token</button>
    <pre class="token-container"></pre>
    `;
        const tokenContainer = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'pre.token-container');
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button').addEventListener('click', () => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                const apiResp = yield this.apis.getApiToken();
                if (apiResp === null) {
                    return;
                }
                tokenContainer.innerHTML = apiResp;
            }))();
        });
    }
}
exports.ApiTokenGenerator = ApiTokenGenerator;
const defineApiTokenGenerator = (name) => (0, lib_es2015_2.defineIfUndefined)(name, ApiTokenGenerator);
exports.defineApiTokenGenerator = defineApiTokenGenerator;
