"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineRecentLocation = exports.RecentLocation = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/webapp-common/lib-es2015");
const lib_es2015_3 = require("@hodol/web-component-helper/lib-es2015");
const google_map_controller_1 = require("./google-map-controller");
class RecentLocation extends HTMLElement {
    constructor() {
        super();
        this.emailSelectorList = [];
        //kakaoMapController: KakaoMapController;
        this.locationApis = new apis_1.default();
        this.sync = () => __awaiter(this, void 0, void 0, function* () {
            while (Number(this.syncPeriodSecondInput.value) > 0) {
                const emails = this.emailSelectorList.filter(emailSelector => emailSelector.isChecked).map(emailSelector => emailSelector.email);
                const entity = yield this.locationApis.getLastLocationByEmails(emails, this.startTimeLocalInput.value);
                if (entity === null) {
                    alert(`Fail to get locations by emails, null returned!`);
                    return;
                }
                //this.kakaoMapController.emptyInfoWindows();
                //this.googleMapController.emptyInfoWindows();
                //this.kakaoMapController.createInfoWindowsByEntity(entity);
                yield this.googleMapController.createInfoWindowsByEntity(entity);
                yield (0, lib_es2015_2.delay)(Number(this.syncPeriodSecondInput.value) * 1000);
            }
            console.log('terminate sync!');
        });
        this.onEmailClick = (email) => {
            this.googleMapController.setCenterByInfoWindowEmail(email);
        };
        this.style.setProperty('display', 'block');
        this.innerHTML = `
    <div class="user-selector-container">
      
    </div>
    <div class="control-panel">
      Start:
      <input type="datetime-local" class="start-time-local" step="1" /> refresh
      for every
      <input
        class="sync-period-second"
        type="number"
      />
      seconds<br />
    </div>
    <div class="recent-location-map"></div>`;
        this.userSelectorContainer = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.user-selector-container');
        this.startTimeLocalInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.start-time-local');
        this.syncPeriodSecondInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.sync-period-second');
        const tempTime = new Date();
        tempTime.setDate(tempTime.getDate() - 1);
        this.startTimeLocalInput.value = (0, lib_es2015_2.toRFC3339WithoutZone)(tempTime);
        this.syncPeriodSecondInput.value = '0';
        this.syncPeriodSecondInput.addEventListener('change', () => {
            const neoSyncPeriod = parseInt(this.syncPeriodSecondInput.value);
            if (neoSyncPeriod < 0) {
                alert('sync period should be zero or positive integer.');
                this.syncPeriodSecondInput.value = '0';
                return;
            }
            void this.sync();
        });
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            // KakaoMapController should be constructed in connectedCallback rather than in constructor, because, it needs map container which has been completely initiated.
            //this.kakaoMapController = new KakaoMapController(getQuerySelectOrThrowError(this, 'div.recent-location-map') as HTMLDivElement);
            this.googleMapController = new google_map_controller_1.GoogleMapController();
            yield this.googleMapController.initMap((0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.recent-location-map'));
            const emails = yield this.locationApis.getEmails();
            if (emails === null) {
                alert(`Fail to get locations by emails, null returned!`);
                return;
            }
            this.emailSelectorList.splice(0, this.emailSelectorList.length);
            emails.forEach(email => {
                const emailSelector = { isChecked: true, email };
                this.emailSelectorList.push(emailSelector);
                const selector = (0, lib_es2015_1.buildNodeByOuterHTML)(`<span class="user-selector-item">
        <input type="checkbox" class="email-selector-is-checked" />
        <button>${email}</button>
      </span>`);
                (0, lib_es2015_1.getQuerySelectOrThrowError)(selector, 'button').addEventListener('click', () => {
                    this.onEmailClick(email);
                });
                const checkbox = (0, lib_es2015_1.getQuerySelectOrThrowError)(selector, 'input.email-selector-is-checked');
                checkbox.addEventListener('change', () => {
                    emailSelector.isChecked = checkbox.checked;
                });
                this.userSelectorContainer.appendChild(selector);
            });
            this.syncPeriodSecondInput.value = '10';
            void this.sync();
        });
    }
}
exports.RecentLocation = RecentLocation;
const defineRecentLocation = (name) => (0, lib_es2015_3.defineIfUndefined)(name, RecentLocation);
exports.defineRecentLocation = defineRecentLocation;
