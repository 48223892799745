"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KakaoMapController = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
class KakaoMapController {
    constructor(mapContainer) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.infoWindows = {};
        this.polyLineWrappers = [];
        while (mapContainer.children.length > 0) {
            mapContainer.removeChild(mapContainer.children[0]);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.kakao = window.kakao;
        const mapOption = {
            center: new this.kakao.maps.LatLng(37.507490496811045, 127.13690734797824), // 지도의 중심좌표
            level: 4, // 지도의 확대 레벨
        };
        this.map = new this.kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다
    }
    setCenterByInfoWindowEmail(email) {
        const targetInfoWindow = this.infoWindows[email];
        if (!(0, lib_es2015_1.isDefined)(targetInfoWindow)) {
            alert('no information for the user: ' + email);
            return;
        }
        this.map.setCenter(targetInfoWindow.getPosition());
    }
    emptyInfoWindows() {
        for (const email in this.infoWindows) {
            this.infoWindows[email].close();
            delete this.infoWindows[email];
        }
    }
    createInfoWindowsByEntity(entity) {
        const now = new Date();
        const oldestAgeInMinute = 180;
        for (const email in entity) {
            for (const utcTime in entity[email]) {
                const sample = entity[email][utcTime];
                const lastPositionDate = new Date(utcTime);
                const ageInMinute = (now.getTime() - lastPositionDate.getTime()) / (1000 * 60);
                const infoWindow = new this.kakao.maps.InfoWindow({
                    map: this.map,
                    position: new this.kakao.maps.LatLng(sample.latitude, sample.longitude),
                    content: `<div class="info-window-container">
                        email: ${email}<br/>
                        time: ${lastPositionDate.toLocaleString()}
                        <div style="height: 5px; background-color: rgb(${Math.floor((144 / oldestAgeInMinute) * Math.min(oldestAgeInMinute, ageInMinute) + 6)}, ${Math.floor((-26 / oldestAgeInMinute) * Math.min(oldestAgeInMinute, ageInMinute) + 176)}, ${Math.floor((144 / oldestAgeInMinute) * Math.min(oldestAgeInMinute, ageInMinute) + 6)});"></div>
                      </div>`,
                });
                this.infoWindows[email] = infoWindow;
            }
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    averageLatLng(latLngList) {
        let latSum = 0.0;
        let lngSum = 0.0;
        for (const latLng of latLngList) {
            latSum = latSum + latLng.getLat();
            lngSum = lngSum + latLng.getLng();
        }
        return new this.kakao.maps.LatLng(latSum / latLngList.length, lngSum / latLngList.length);
    }
    deletePolyLines() {
        for (const polyLineWrapper of this.polyLineWrappers) {
            polyLineWrapper.polyLine.setMap(null);
        }
        this.polyLineWrappers.splice(0, this.polyLineWrappers.length);
    }
    drawPolyLinesByEntity(entity, samplingDivisor) {
        const dummyDate = new Date();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const drawnLatLng = [];
        for (const userName in entity) {
            // only one user, FYI.
            const locationHistory = entity[userName];
            let previousLatLngWrapper = {
                latLng: null,
                localDatetime: dummyDate,
            };
            let loop = 0;
            const historyLength = Object.keys(locationHistory).length;
            for (const utcTime in locationHistory) {
                // ES6 preserves the order of members in JSON Object in which they were created.
                if (loop % samplingDivisor > 0 && loop < historyLength - 1) {
                    loop++;
                    continue;
                }
                const sample = locationHistory[utcTime];
                const latLng = new this.kakao.maps.LatLng(sample.latitude, sample.longitude);
                if (previousLatLngWrapper.latLng == null) {
                    previousLatLngWrapper = {
                        latLng,
                        localDatetime: new Date(utcTime),
                    };
                    drawnLatLng.push(latLng);
                    loop++;
                    continue;
                }
                const currentLatLngWrapper = {
                    latLng,
                    localDatetime: new Date(utcTime),
                };
                const ratio = loop / historyLength;
                const strokeColor = `rgb(0, ${Math.floor(255 * ratio)}, ${Math.floor(255 - 255 * ratio)})`;
                const polyline = new this.kakao.maps.Polyline({
                    path: [previousLatLngWrapper.latLng, currentLatLngWrapper.latLng],
                    strokeWeight: 5,
                    strokeColor,
                    strokeOpacity: 1,
                    strokeStyle: 'solid',
                });
                polyline.setMap(this.map);
                const infoWindow = new this.kakao.maps.InfoWindow({
                    position: currentLatLngWrapper.latLng.getLat() > previousLatLngWrapper.latLng.getLat() ? currentLatLngWrapper.latLng : previousLatLngWrapper.latLng,
                    content: `<div class="polyline-info-window-container">
                        start: ${previousLatLngWrapper.localDatetime.toLocaleString()}<br/>
                        stop: ${currentLatLngWrapper.localDatetime.toLocaleString()}<br/>
                        avgSpeed: ${(polyline.getLength() /
                        1000 /
                        ((currentLatLngWrapper.localDatetime.getTime() - previousLatLngWrapper.localDatetime.getTime()) / (1000 * 60 * 60))).toFixed(4)}km/h <br/>
                      </div>`,
                });
                const polyLineWrapper = {
                    polyLine: polyline,
                    strokeColor,
                    infoWindow,
                };
                this.kakao.maps.event.addListener(polyline, 'mouseover', () => {
                    polyline.setOptions({
                        strokeColor: '#FF0000',
                    });
                    infoWindow.open(this.map);
                });
                this.kakao.maps.event.addListener(polyline, 'mouseout', () => {
                    polyline.setOptions({
                        strokeColor: polyLineWrapper.strokeColor,
                    });
                    infoWindow.close();
                });
                this.polyLineWrappers.push(polyLineWrapper);
                previousLatLngWrapper = currentLatLngWrapper;
                drawnLatLng.push(latLng);
                loop++;
            }
        }
        this.map.setCenter(this.averageLatLng(drawnLatLng));
    }
}
exports.KakaoMapController = KakaoMapController;
